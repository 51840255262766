import React, { useCallback } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Grid, Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';

export interface BackButtonProps {
  onlyIcon?: boolean;
  to?: string;
  className?: string;
  backText?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ onlyIcon = false, backText = 'Back', to, className }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  }, [to, history]);

  if (onlyIcon) {
    return (
      <IconButton onClick={onClick} className={className}>
        <ArrowBack/>
      </IconButton>
    );
  }

  return (
    <Button onClick={onClick} className={className}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Grid container direction="column" justifyContent="center">
            <ArrowBack/>
          </Grid>
        </Grid>
        <Grid item>
          {backText}
        </Grid>
      </Grid>
    </Button>
  );
};
