import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { ErrorHandler } from '@hu-care/react-utils';

export interface ErrorProps {
  error: import('@apollo/client').ApolloError | ErrorHandler | string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  const e = !(error instanceof ErrorHandler) ? new ErrorHandler(error) : error;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        margin: 0,
        width: 'auto',
      }}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Warning color="error"/>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="error">
          {e.message}
        </Typography>
      </Grid>
    </Grid>
  );
};
