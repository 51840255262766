import { createTheme, responsiveFontSizes } from '@mui/material';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f1b80d',
      contrastText: grey[50],
    },
    secondary: {
      main: '#4E4F51',
      light: '#717274',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#4E4F51',
          color: '#ffffff',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
