import React from 'react';
import { Toolbar, Grid, Typography, Divider } from '@mui/material';
import { Info } from '@mui/icons-material';

export const EnvBar: React.FC = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return (
      <></>
    );
  }
  return (
    <>
      <Toolbar
        variant="dense"
        sx={{
          minHeight: theme => theme.spacing(5),
        }}
      >
        <Grid item container alignItems="center">
          <Info
            fontSize="small"
            color="action"
            sx={{
              marginRight: theme => theme.spacing(1),
            }}
          />
          <Grid item sm>
            <Typography variant="caption" color="textSecondary">
              ID:
              <strong>{process.env.REACT_APP_NAME}</strong>
              - Env:
              <strong>{process.env.REACT_APP_ENV}</strong>
              - Version:
              <strong>{process.env.REACT_APP_VERSION}</strong>
              {process.env.REACT_APP_ENV === 'development' && `- Endpoint:${process.env.REACT_APP_API_ENDPOINT}`}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider/>
    </>
  );
};
