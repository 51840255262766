import React, { memo, PropsWithChildren } from 'react';
import {
  Container,
  Toolbar,
  ContainerProps,
  Box,
  BoxProps,
} from '@mui/material';
import { LayoutState, useLayout } from '../contexts';
import { EnvBar } from './env-bar';

export interface NavBarProps extends LayoutState {
  getTitle?: (props: any) => any;
}

export interface LayoutProps extends ContainerProps {
  navBar: (props: NavBarProps) => JSX.Element;
  rootClassName?: string;
  mainClassName?: string;
  showEnvBar?: boolean;
  rootProps?: BoxProps;
  mainProps?: BoxProps;
}

export const Layout = memo<PropsWithChildren<LayoutProps>>(({
  children,
  navBar,
  rootClassName,
  mainClassName,
  showEnvBar = true,
  rootProps,
  mainProps,
  ...containerProps
}) => {
  const state = useLayout();

  const NavBar = navBar(state);

  return (
    <Box
      className={rootClassName}
      style={{ backgroundColor: state.background }}
      display="flex"
      {...rootProps}
    >
      {NavBar}
      <Box
        component="main"
        className={mainClassName}
        flexGrow={1}
        {...mainProps}
      >
        <Toolbar/>
        { showEnvBar && <EnvBar/> }
        <Container maxWidth="xl" {...containerProps}>
          {children}
        </Container>
      </Box>
    </Box>
  );
});
