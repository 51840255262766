import React from 'react';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { BackButton } from './back-button';

export interface PageTitleProps {
  title: string;
  backUrl?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, backUrl }) => {
  const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('xs'));

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <BackButton onlyIcon to={backUrl}/>
      </Grid>
      <Grid item>
        <Typography variant="h6" align={isDesktop ? 'left' : 'center'}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
