import React, {
  createContext, Dispatch, useCallback, useContext, useMemo, useReducer,
} from 'react';
import merge from 'lodash.merge';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import {
  Actions,
  DialogAction,
  dialogActions,
  DialogActionsType,
  dialogInitialState,
  DialogState,
} from './actions';
import { ConfirmDialog, ConfirmDialogProps } from './components/confirm-dialog';

// combine initial states
type StateType = DialogState;

type ActionType = DialogAction;

const defaultInitialState: StateType = {
  ...dialogInitialState,
};
// combine actions
const ReduceActions: DialogActionsType = {
  ...dialogActions,
};

export interface StoreCtx {
  state: StateType;
  dispatch: Dispatch<ActionType>;
}

const Context = createContext<StoreCtx>({ state: defaultInitialState } as StoreCtx);

const reducer = (state: StateType, { type, ...newState }: ActionType): StateType => {
  const act = ReduceActions[type];
  const update = act(state, newState as any);
  return {
    ...state,
    ...update,
  };
};

export type SnackbarProps = Omit<SnackbarProviderProps, 'children'>;

export interface StoreProviderProps {
  initialState?: Partial<StateType>;
  snackbarProps?: SnackbarProps;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children, initialState, snackbarProps }) => {
  const initState = useMemo(() => merge(defaultInitialState, initialState), [initialState]);
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <SnackbarProvider {...snackbarProps}>
        {children}
      </SnackbarProvider>
    </Context.Provider>
  );
};

export const useStore = () => {
  const { state, dispatch } = useContext(Context);

  const confirm = useCallback((
    {
      onCancel,
      onConfirm,
      confirmText,
      cancelText,
      message,
      ...dispatchProps
    }: ConfirmDialogProps & DialogAction,
  ) => {
    const onClose = () => dispatch({
      type: Actions.DIALOG_CLOSE,
    });
    dispatch({
      type: Actions.DIALOG_OPEN,
      onClose,
      body: <ConfirmDialog
        message={message}
        confirmText={confirmText}
        cancelText={cancelText}
        onConfirm={onConfirm}
        onCancel={() => {
          if (typeof onCancel === 'function') {
            onCancel();
          }
          onClose();
        }}
      />,
      ...dispatchProps,
    });
  }, [dispatch]);

  return useMemo(() => ({
    state,
    dispatch,
    confirm,
  }), [
    state,
    dispatch,
    confirm,
  ]);
};

export * from './actions/actions';
