import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from './views/components/error.boundary';
import { SnackbarProps, StoreProvider } from '@hu-care/react-ui-store';
import theme from '@hu-care/theme';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { Router } from 'react-router';
import { QueryParamProvider } from 'use-query-params';
import history from './utils/history';
import { AuthProvider } from './contexts/auth.context';
import { Route } from 'react-router-dom';
import { DownloadProvider } from './contexts/download.context';

window.userId = '56ebffc5-0819-4123-a626-03021a8c4405';

const snackbarProps: SnackbarProps = {
  preventDuplicate: true,
  disableWindowBlurListener: true,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
};

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider snackbarProps={snackbarProps}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AuthProvider skipAll={!!window.userId && process.env.NODE_ENV === 'development'}>
                <DownloadProvider>
                  <App/>
                </DownloadProvider>
              </AuthProvider>
            </QueryParamProvider>
          </Router>
        </LocalizationProvider>
      </MuiThemeProvider>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
