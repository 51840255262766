import React from 'react';
import {
  DialogTitleProps as MuiDialogTitleProps,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
// eslint-disable-next-line import/no-cycle
import { useStore } from '../context';

export interface DialogTitleProps extends MuiDialogTitleProps {
  fullScreen?: boolean;
  onClose?: (...attr: any) => void;
  disableCloseButton?: boolean;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({ children, onClose, fullScreen, disableCloseButton, ...other }) => (
  <MuiDialogTitle
    {...other}
    className={other.className}
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...fullScreen && {
        backgroundColor: theme => theme.palette.primary.main,
        color: theme => theme.palette.primary.contrastText,
        marginBottom: theme => theme.spacing(2),
        boxShadow: theme => theme.shadows[4],
      },
    }}
  >
    {typeof children === 'string' ? (
      <Typography variant="h6">{children}</Typography>
    ) : children}
    {(onClose && !disableCloseButton) ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        size={fullScreen ? 'small' : undefined}
        sx={{
          marginLeft: 'auto',
          marginRight: theme => `-${theme.spacing(1)}`,
          marginTop: theme => `-${theme.spacing(1)}`,
          marginBottom: theme => `-${theme.spacing(1)}`,
          color: 'inherit',
        }}
      >
        <Close/>
      </IconButton>
    ) : null}
  </MuiDialogTitle>
);

export const SharedDialog: React.FC = () => {
  const { state: { dialog: { body, title, dialogTitleProps, ...dialog } } } = useStore();

  return (
    <>
      <Dialog
        PaperProps={{ square: true }}
        aria-labelledby="shared-dialog-title"
        {...dialog}
      >
        {dialog.open && (
          <>
            <DialogTitle
              id="shared-dialog-title"
              onClose={dialog.onClose}
              fullScreen={dialog.fullScreen}
              {...dialogTitleProps}
            >
              {title}
            </DialogTitle>
            <DialogContent>
              {body}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};
